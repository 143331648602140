export const environment = {
  production: true,
  protocol:'https',
  janusIp:"stage.traction-project.eu",
  janusHttpPort: 8089,
  jetsonIp:"192.168.15.141",
  motionProtocol: 'wss',
  motionServer:"devstage.traction-project.eu",
  motionServerPort: 8080,
  motionChannel:"testing",
  sharedStateServer:"devstage.traction-project.eu:8083",
  sharedStateChannel:"traction22",
  controlServer:"devstage.traction-project.eu:8083",
  staticServer:"devstage.traction-project.eu",
  mediaVault:"https://devstage.traction-project.eu/",
  cloudfront:" https://d2pjmukh9qywdn.cloudfront.net/",
  resolutions:["1080p","720p","480p","360p","240p","180p"],
  subdomains:["","https://d2pjmukh9qywdn.cloudfront.net/","https://stagem2.traction-project.eu/","https://stagem3.traction-project.eu/"],
  keycloak_app:"stage",
  publicPaths:['/assets', '/traction','/camera','/audio','/player','/api/upload/encode'],
  ytServer:"https://cloud.flexcontrol.net:4200",
  ytPass:"dkeiomkdoleeeksEW_222"
};
